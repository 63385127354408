import React, { useState } from "react";
import { Link } from "gatsby"; 
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Slide, Flip } from 'react-reveal';
import Wave from 'react-wavify';

import { InPageBot } from "@leadoo/react-bot";

// images
import banner_img from "../images/service.svg";
import scanner_setting_img from "../images/scanner_settings.svg";

const BOT_CODE = "NUbkN9AF";

function Service() {
    const [chatbot_1, setChatbot_1] = useState(false);
    const [chatbot_2, setChatbot_2] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>Service och Support | Optosweden</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Vi levererar högkvalitativ, professionell och tekniskt kunnig Certifierad Service och Support" />
                <meta name="keywords" content="Skanner | Service | Support | supportavdelning" />
                <meta property="og:title" content="Optosweden AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Vi levererar högkvalitativ, professionell och tekniskt kunnig Certifierad Service och Support" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.optosweden.se/service" />
                <link rel="cannonical" href="https://www.optosweden.se/service" />
            </Helmet>

            <section className="w-screen bg-white border-t border-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 py-24 gap-20">
                        <Slide left>
                            <div className="flex flex-col items-start justify-center">
                                <h1 className="title-font font-semibold text-3xl text-blue-custome">Certifierad Service &amp; Support</h1>
                                <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                    OptoSweden erbjuder utbildad &amp; certifierad personal på plats och på distans.
                                    <br/><br/>
                                    Vår service-och supportavdelning hjälper er 
                                    med företagets skanner och mjukvara. Vi erbjuder service 
                                    och support men även utbildning på er skanner och mjukvara 
                                    i hela Norden.
                                </p>
                                <button onClick={() => setChatbot_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                                {chatbot_1 
                                    ? 
                                        <div className="h-full w-full mt-10">
                                            <InPageBot code={BOT_CODE} seamless/>
                                        </div> 
                                    : "" 
                                }
                            </div>
                        </Slide>
                        <Slide right>
                            <div className="w-1/2 p-4 mx-auto">
                                <img className="w-full" src={banner_img} alt="service och support optosweden" />
                            </div>
                        </Slide>
                        

                    </div>
                </div>
                <Wave 
                    className="-mb-2"
                    fill='#F3F4F6'
                    paused={false}
                    options={{
                    height: 20,
                    amplitude: 50,
                    speed: 0.15,
                    points: 3
                    }}
                />
            </section>


            <section className="bg-gray-100 pb-32">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="pb-10 flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Hem</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">service &amp; support</span>
                    </div>
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="w-full h-full">
                            <div className="relative w-full h-full bg-scanner-service bg-cover bg-no-repeat bg-center rounded-md shadow-lg" style={{minHeight: "400px", maxHeight: "570px"}}>
                                <img className="hidden lg:block absolute lg:-bottom-10 lg:-left-10  w-40 h-40 rounded-full shadow-2xl" src={scanner_setting_img} alt="service och support optosweden" />
                            </div>

                        </div>
                        <div className="w-full flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">Vi levererar högkvalitativ, professionell och tekniskt kunnig supporthjälp till din skanner</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                Vi erbjuder supporttjänster för installation samt utbildning, så att 
                                du och din personal vet hur ni kan använda och ta hand om er 
                                skanner på bästa sätt.
                                <br/><br/>
                                De flesta av våra kunder har fasta avtal på sin utrustning 
                                vilket ger dem en garanti för att vi påbörjar att lösa eventuella 
                                problem inom en viss tid. Ett avtal går att utforma specifikt 
                                för en kund och kan vara ett all inklusive avtal eller att vi 
                                kontaktas vid behov och ni betalar de faktiska kostnaderna 
                                som då uppstår.
                                <br/><br/>
                                Vi har även möjligheten att hyra ut ersättningsutrustning 
                                vid långa driftstopp eller inför tillfälliga projekt. Ni 
                                kan även ha ett så kallat ”swapavtal” där enheten helt 
                                enkelt byts ut när något uppstår.
                                <br/><br/>
                                Kontakta oss för mer information!
                            </p>
                            <button onClick={() => setChatbot_2(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                            {chatbot_2 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={BOT_CODE} seamless/>
                                    </div> 
                                : "" 
                            }
                            </div>
                    </div>
                </div>
            </section>

            <section className="py-32">
                {/*-------------- other services - START ---------------*/}
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-20">
                        <Flip top>
                            <div className="px-8 md:px-0">
                                <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-custome shadow-2xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"/>
                                        <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>    
                                    </svg>
                                </div>
                                <h2 className="uppercase mt-6 text-blue-custome font-medium mb-3">Skanners</h2>
                                <p className="font-light text-sm text-gray-500">
                                    På OptoSweden har vi över 20 års erfarenhet av skanningslösningar, 
                                    där vi som återförsäljare av flera marknadsledande varumärken har 
                                    kompetensen och möjligheterna att guida dig rätt
                                </p>
                                <Link to="/skanners" className="mt-3 text-blue-custome flex items-center hover:text-blue-600">
                                    Läs mer
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </Link>
                            </div>
                        </Flip>

                        <Flip top>
                            <div className="px-8 md:px-0">
                                <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-custome shadow-2xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
                                    </svg>
                                </div>
                                <h2 className="uppercase mt-6 text-blue-custome font-medium mb-3">Skanningsprogramvara</h2>
                                <p className="font-light text-sm text-gray-500">
                                    Hos oss kan ni välja den programvara för bildfångst som passar just 
                                    era behov. Samtidigt kan ni vara säkra på att ni får hjälp i 
                                    ert val från våra kunniga och erfarna säljare, tekniker och konsulter.
                                </p>
                                <Link to="/skanningsprogramvara" className="mt-3 text-blue-custome flex items-center hover:text-blue-600">
                                    Läs mer
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </Link>
                            </div>
                        </Flip>

                        <Flip top>
                            <div className="px-8 md:px-0">
                                <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-custome shadow-2xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V9H2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM2 12h12v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-2zM.5 10a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H.5z"/>
                                    </svg>
                                </div>
                                <h2 className="uppercase mt-6 text-blue-custome font-medium mb-3">Intelligenta automationsplattformar</h2>
                                <p className="font-light text-sm text-gray-500">
                                    Med marknadsledande teknik analyserar vi dina dokument 
                                    oavsett format. Vi läser ut den data du behöver och gör 
                                    den tillgänglig för dig.
                                </p>
                                <Link to="/dokument" className="mt-3 text-blue-custome flex items-center hover:text-blue-600">
                                    Läs mer
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </Link>
                            </div> 
                        </Flip>                       
                    </div> 
                </div> 
                {/*-------------- other services - END ---------------*/}
            </section>

        </Layout>
    );
}
export default Service;